.typesDiv {
  box-shadow: 0.5px 0.5px 5px gray;
  cursor: pointer;
  width: 100%;
  height: 90%;
}
.typesTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
}
.typesicon {
    font-weight: 700;
    font-size: 30px;
}

.typesDiv:hover{
  box-shadow: 1px 1px 7px gray inset;
}
