.loginBtn{
    background-color: var(--main-color);
    width: 20%;
    border: 1px solid white;
}

.loginBtn:hover,.loginBtn:focus{
    background-color: var(--main-color);
    box-shadow: 1px 1px 5px var(--main-color);
    border: 1px solid var(--main-color);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #F4AED0;
    border: 1px solid white;
}