.modalTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: #000000;
}

.nameText {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #000000;
}