.textTitles {
  font-weight: 400;
  font-size: 20px;
  line-height: 46px;
}

.horizLine {
  border: 1px solid rgba(196, 196, 196, 0.5);
  transform: rotate(0.14deg);
  width: 95%;
  margin: auto;
}

.btnSendRate {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  width: 10%;
}
.btnSendRate:hover {
  background-color: var(--main-color);
  box-shadow: 1px 1px 7px var(--main-color);
  border: 1px solid white;
}
.btnSendRate:focus {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
}

@media only screen and (max-width: 767.98px) {
  .btnSendRate {
    width: 100% !important;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
  .btnSendRate {
    width: 30%;
  }
}
