.addButton {
  background-color: var(--main-color);
  border: 1px solid white;
  height: fit-content;
}
.addButton:hover {
  box-shadow: 1px 1px 7px var(--main-color);
  background-color: var(--main-color);
  border: 1px solid white;
}
.addButton:focus {
  background-color: var(--main-color);
  border: 1px solid white;
}
.input-group.md-form.form-sm.form-1 input {
  border: 1px solid #bdbdbd;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
@media only screen and (max-width: 767.98px) {
  .addButton {
    width: 100%;
  }
}
body {
  background-color: #f5f5f5;
}
