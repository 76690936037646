.userCardCol {
  width: 19.9%;
  margin-bottom: 20px;
}
.userCard {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.userImgC {
  max-width: 75%;
  width: auto;
  height: 150px;
  border-radius: 50%;
  padding: 2%;
  margin: auto;
}
.cardTitleName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rateBtn {
  color: white;
  background: #31d2f2;
  border: 1px solid #25cff2;
}
/* @media only screen and (max-width: 600px) {
  .userCardCol {
    width: 100%;
  }
} */
/* // X-Small devices (portrait phones, less than 576px) */
@media only screen and (max-width: 575.98px) {
  .userCardCol {
    width: 100%;
  }
}

/* // Small devices (landscape phones, less than 768px) */
@media only screen and (max-width: 767.98px) {
  .userCardCol {
    width: 100% !important;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media only screen and (max-width: 991.98px) {
  .userCardCol {
    width: 50%;
  }
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}

/* // X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {
}

/* // XX-Large devices (larger desktops) */
/* // No media query since the xxl breakpoint has no upper bound on its width */
