.notfCard {
  box-shadow: 1px 0px 2px var(--main-color);
}
.notfCard:hover {
  box-shadow: 1px 0px 2px var(--main-color) inset;
}

.agreeBtn {
  color: white;
  background-color: var(--main-color);
  width: fit-content;
}

.agreeBtn:hover {
  color: white;
  box-shadow: 1px 1px 5px var(--main-color);
}

.disagreeBtn {
  color: var(--main-color);
  background-color: white;
  border: 1px solid var(--main-color);
  width: fit-content;
}

.disagreeBtn:hover {
  color: var(--main-color);
  box-shadow: 1px 1px 5px var(--main-color);
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
