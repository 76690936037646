.titleTextF {
  font-weight: 600;
  font-size: 27px;
  line-height: 150%;
}

.textUserData {
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
}