.navbarContainer{
    background-color: var(--main-color);
    box-shadow: 1px 1px 5px gray;
}
.userImgNav{
    cursor: pointer;
    box-shadow: 1px 1px 7px gray;
}
.iconNav{
    color: white;
    font-size: x-large;
    cursor: pointer;
}
.iconNav:hover{
    color: rgb(168, 168, 168);
}