.cardDiv {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  height: fit-content;
  margin: 0;
}

.titleText {
  font-weight: 700;
  font-size: 15px;
  line-height: 150%;
}

.bg-progVariant {
  background-color: var(--main-color) !important;
}

.capacityText {
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  text-align: center;
}

@media (max-width: 480px) {
  .capacityText {
    font-size: 28px;
  }
  .divider {
    height: 10px;
  }
}
